<template>
  <v-app id="page-container">
    <div v-if="$route.name !== 'Login'">
      <v-navigation-drawer
        v-if="isAuthenticated"
        id="app-drawer"
        app
        persistent
        mobile-breakpoint="991"
        width="280"
        v-model="drawer"
        :disable-route-watcher="$route.params.newSession"
      >
        <SideNav />
      </v-navigation-drawer>
      <!-- <order-details-side-bar v-if="$route.name === 'Orders'" /> -->
      <v-app-bar color="primary" dark app clipped-right>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
    </div>

    <v-main id="content-wrap">
      <router-view :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null" />
    </v-main>

    <v-snackbar
      v-model="snackBar.show"
      :multi-line="false"
      color="default"
      :timeout="3000"
    >
      {{ snackBar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="$store.dispatch('hideSnackbar')"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-footer
      app
      class="secondary white--text"
      absolute
      v-if="$route.name !== 'Login' && $route.name !== 'Payment'"
    >
      <span>SolidTech -</span>
      <span class="ml-1">&copy;{{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>
<script>
import SideNav from "./components/nav/SideNav";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    SideNav,
    // OrderDetailsSideBar
  },
  data: () => ({
    drawer: null,
    colors: [
      "pink",
      "purple",
      "deep-purple",
      "indigo",
      "blue",
      "light-blue",
      "cyan",
      "teal",
      "green",
      "light-green",
      "lime",
      "amber",
      "orange",
      "deep-orange",
    ],
  }),
  async mounted() {
    if (!this.$auth.user) {
      this.$router.push("/login");
    } else {
      if (this.$router.currentRoute.path === "/") {
        this.$router.push("/board");
      }
    }
  },
  methods: {
    async storeToken() {
      const token = await this.$auth.getTokenSilently();
      this.$store.dispatch("setToken", token);
    },
  },
  computed: {
    isAuthenticated() {
      this.$store.dispatch("setAuth", this.$auth.isAuthenticated);
      return this.$auth.isAuthenticated;
    },
    ...mapGetters(["token", "snackBar", "subdivisions"]),
  },
  watch: {
    token(c) {
      if (c) {
        console.log(c); //TODO should remove this as a security issue. We don't want their token being displayed in prod
        this.$store.dispatch("getPermissions");
        this.$store.dispatch("getSubdivisions");
        this.$store.dispatch("getUsers");
        this.$store.dispatch("getTeams");
      }
    },
    isAuthenticated() {
      this.storeToken();
    },
  },
};
</script>
<style>
.underlined {
  text-decoration: underline;
}
#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 2.5rem; /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}
</style>
0000000000
