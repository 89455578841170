<template>
  <div class="mt-3 mx-3">
    <job-calendar />
    <div class="d-block">
      <v-row class="text-center" justify="center">
        <v-col cols="12" md="3">
          <div class="pa-4 done text-no-wrap rounded-pill">Done Jobs</div>
        </v-col>

        <v-col cols="12" md="3">
          <div class="pa-4 inProgress text-no-wrap rounded-pill">
            In Progress Jobs
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="pa-4 onSchedule text-no-wrap rounded-pill">
            Scheduled Jobs
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import JobCalendar from "../components/calendar/JobCalendar.vue";

export default {
  components: { JobCalendar },
  data: () => ({}),
  mounted() {
    if (!this.permissions) {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.$store.dispatch("getSubdivisions");
      this.$store.dispatch("getPermissions");
    },
  },
  computed: {
    ...mapGetters([
      "subdivisions",
      "teams",
      "isLoading",
      "users",
      "adminCms",
      "permissions",
      "token",
    ]),
  },
};
</script>

<style>
.done {
  background-color: #99b898;
}
.inProgress {
  background-color: #ff847c;
}
.onSchedule {
  background-color: #45ada8;
}
</style>