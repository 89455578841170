import Vue from "vue";
import Vuex from "vuex";
import jobs from "./modules/jobs"


Vue.use(Vuex);

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default new Vuex.Store({
  // strict: process.env.NODE_ENV !== "production",
  modules: {
    jobs
  },
});
