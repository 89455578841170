<template>
  <v-card raised outlined>
    <v-row align="center" class="mt-5" justify="center">
      <v-container>
        <p class="text-center title">{{ lotProp ? "Edit" : "Add" }} Lot</p>
        <v-form class="ml-5 mr-5 mb-5 mt-5" ref="form" v-model="valid">
          <v-col v-if="lot" cols="12">
            <v-select
              v-model="lot._id"
              :items="mappedSubdivisions"
              label="Subdivision"
            ></v-select>
            <v-text-field
              v-model="lot.lot_number"
              :disabled="!lot._id"
              :rules="[(v) => !!v || 'Lot # is required']"
              label="Lot #"
            ></v-text-field>
            <v-text-field
              v-model="lot.lot_address"
              :disabled="!lot._id"
              label="Lot address"
            ></v-text-field>
            <v-text-field
              v-model="lot.lot_manager"
              :disabled="!lot._id"
              :rules="[(v) => !!v || 'Last name is required']"
              label="Superintendent"
            ></v-text-field>
          </v-col>
        </v-form>
      </v-container>
    </v-row>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="error" text @click="cancel">Cancel</v-btn>
      <v-btn color="primary" :disabled="!lot._id" text @click="save(lot)"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import _cloneDeep from "lodash.clonedeep";

function initialState() {
  return {
    lot: {
      lot_number: "",
      lot_address: "",
      lot_manager: "",
      _id: "",
    },
    valid: false,
  };
}

export default {
  props: ["subdivisions", "save", "cancel", "lotProp"],
  data() {
    return initialState();
  },
  mounted() {
    if (this.lotProp) {
      Object.keys(this.lot).forEach((k) => {
        this.lot[k] = this.lotProp[k];
      });
      this.lot.lot_id = this.lotProp.lot_id;
    }
  },
  created() {
    this.resetWindow();
  },
  methods: {
    resetWindow() {
      Object.assign(this.$data, initialState());
    },
  },
  computed: {
    mappedSubdivisions() {
      return _cloneDeep(this.subdivisions)
        .map((x) => {
          return {
            text: x.subdivision_name,
            value: x._id,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  watch: {
    lotProp(l) {
      this.lot = l;
    },
  },
};
</script>

<style>
</style>
