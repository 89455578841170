<template>
  <v-container>
    <v-card-title>{{ title }}</v-card-title>
    <v-row
      v-for="(comment, i) in comments"
      :key="i"
      no-gutters
      dense
      class="mt-3"
    >
      <v-card>
        <div class="d-flex">
          <div>
            <v-card-title class="text-h6" v-text="comment.title"></v-card-title>
            <v-card-subtitle class="caption">
              {{ $dayjs(comment.date_created).format("DD/MM/YYYY") }} -
              {{ comment.email }}
            </v-card-subtitle>
            <p class="mt-3 ml-4">{{ comment.text }}</p>
          </div>

          <v-avatar
            v-if="comment.photo_urls"
            @click="displayPhotos(comment.date_created)"
            class="ma-1"
            size="50"
            tile
          >
            <v-img :src="comment.photo_urls[0]"></v-img>
          </v-avatar>
        </div>
      </v-card>
      <v-divider></v-divider>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["title", "comments", "displayPhotos"],
};
</script>

<style></style>
