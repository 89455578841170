<template>
  <div>
    <p class="mt-3 text-center">
      {{
        checkVerticalAlignment ? "Rotate your phone for best experience" : ""
      }}
    </p>
    <v-row justify="center">
      <v-switch
        inset
        v-model="showTodayOnly"
        :label="showTodayOnly ? 'Showing day' : 'Showing week'"
      ></v-switch>
    </v-row>
    <kanban-board
      v-if="modifiedBlocks"
      :stages="stages.map((x) => `status-${x.id}`)"
      :blocks="modifiedBlocks"
      @update-block="updateBlock"
    >
      <div v-for="stage in stages" :slot="`status-${stage.id}`" :key="stage.id">
        <h2>{{ stage.name }}</h2>
      </div>
      <div v-for="block in modifiedBlocks" :slot="block.id" :key="block.id">
        <div>
          <div class="caption font-weight-medium">{{ block.title }}</div>
          <v-btn x-small text @click="selectJob(block)">View details</v-btn>
        </div>
      </div>
    </kanban-board>
    <div class="text-center">
      <v-dialog
        v-if="selectedJob"
        v-model="openDialog"
        width="1400"
        height="1400"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <v-app-bar dark color="blue">
          <v-toolbar-title>{{ `${selectedJob.title}` }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="$vuetify.breakpoint.smAndDown" icon>
            <v-icon @click="closeDialog()" color="red">mdi-close</v-icon>
          </v-btn>
          <v-progress-circular
            v-if="isLoading"
            :width="2"
            indeterminate
          ></v-progress-circular>
        </v-app-bar>
        <v-card class="pb-4">
          <v-card-title class="text-h6 grey lighten-2">{{
            `${selectedJob.lot_address} Sub: ${selectedJob.subdivision_name}`
          }}</v-card-title>
          <v-divider></v-divider>
          <job-details
            v-if="selectedJob"
            class="mx-5 mt-5 mb-5"
            :mappedSubdivisons="mappedSubdivisions"
            :job="selectedJob"
            :isLoading="isLoading"
            :closeDialog="closeDialog"
          />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import "../../styles/kanban.css";
import JobDetails from "./JobDetails.vue";
import { mapGetters } from "vuex";
import _cloneDeep from "lodash.clonedeep";

export default {
  props: ["jobId"],
  components: { JobDetails },
  data() {
    return {
      stages: [],
      selectedJob: null,
      openDialog: false,
      setter: false,
    };
  },
  mounted() {
    this.stages = this.getCurrentWeekDays();
    this.$store.dispatch("getSubdivisions");
    if (this.jobId && this.mappedSubdivisions) {
      this.selectJob(this.jobId);
    }
  },
  methods: {
    closeDialog() {
      this.openDialog = false;
    },
    selectJob(job) {
      if (typeof job === "string") {
        this.selectedJob = this.mappedSubdivisions.find(
          (j) => j.job_id === this.jobId
        );
      } else {
        this.selectedJob = this.mappedSubdivisions.find(
          (j) => j.job_id === job.id
        );
      }
      if (this.selectedJob) {
        this.openDialog = !this.openDialog;
      }
    },
    convertStatusType(status, foundStage = null, formatedDate = null) {
      // In progress or done
      if (!status || status === "status-7" || status === "status-8") {
        return status;
      } else {
        if (foundStage && formatedDate) {
          return this.$dayjs(
            `${foundStage.name} ${formatedDate}`
          ).toISOString();
        } else {
          const name = this.$dayjs(status).format("dddd MMM D");
          const foundStatus = this.stages.find((s) => s.name === name);
          if (foundStatus) {
            return `status-${foundStatus.id}`;
          }
        }
      }
    },
    updateBlock(id, status) {
      // const foundBlock = this.modifiedBlocks.find(b => b.id === id)
      const foundStage = this.stages.find((s) => `status-${s.id}` === status);
      const formatedDate = this.$dayjs().format("YYYY");

      const mappedStatus = this.convertStatusType(
        status,
        foundStage,
        formatedDate
      );

      const foundSubdivision = this.mappedSubdivisions.find(
        (s) => s.job_id === id
      );
      const job = {
        job_id: id,
        lot_id: foundSubdivision.lot_id,
        subdivision_name: foundSubdivision.subdivision_name,
        status: mappedStatus,
      };
      this.$store.dispatch("updateJobStatus", job);
    },
    getCurrentWeekDays() {
      let weekDayArray;
      let today = this.$dayjs();
      let currentDayOfWeek = today.day();

      if (currentDayOfWeek === 6 || currentDayOfWeek === 7) {
        let followingMonday =
          currentDayOfWeek === 6
            ? this.$dayjs().add(2, "day")
            : this.$dayjs().add(1, "day");

        today = followingMonday;

        weekDayArray = Array(4)
          .fill(0)
          .map((v, i) => {
            return {
              id: i + 1,
              name: today.add(i + 1, "day").format("dddd MMM D"),
            };
          });
      } else {
        let diff = 5 - currentDayOfWeek;

        weekDayArray = Array(diff)
          .fill(0)
          .map((v, i) => {
            return {
              id: i + 1,
              name: today.add(i + 1, "day").format("dddd MMM D"),
            };
          });
      }

      weekDayArray.unshift({
        id: 0,
        name: this.$dayjs(today).format("dddd MMM D"),
      });

      weekDayArray.push({
        id: 7,
        name: "In Progress",
      });

      weekDayArray.push({
        id: 8,
        name: "Done",
      });
      return weekDayArray;
    },
  },

  computed: {
    ...mapGetters(["token", "subdivisions", "isLoading"]),
    mappedSubdivisions() {
      const team_id =
        this.$auth.user["https://my-app.example.com/app_metadata"].team_id;
      const role = this.$auth.user["https://my-app.example.com/roles"][0];
      const clonedSubDivs = _cloneDeep(this.subdivisions);
      return clonedSubDivs
        .flatMap((s) => {
          return s.lots.flatMap((l) => {
            return l.jobs.map((j) => {
              delete l.jobs;
              delete s.lots;
              return Object.assign({}, j, l, s);
            });
          });
        })
        .filter((j) => role === "Admin" || j.team_id === team_id);
    },
    showTodayOnly: {
      get: function () {
        return this.setter;
      },
      set: function (newValue) {
        if (newValue) {
          this.stages = this.stages.filter(
            (s) =>
              s.name === this.$dayjs().format("dddd MMM D") ||
              s.name === "Done" ||
              s.name === "In Progress"
          );
        } else {
          this.stages = this.getCurrentWeekDays();
        }
      },
    },
    modifiedBlocks() {
      if (!this.stages.length) return [];
      return this.mappedSubdivisions.map((x) => {
        return {
          status: this.convertStatusType(x.status),
          id: x.job_id,
          title: `${x.title} | ${x.subdivision_name} ${x.lot_number}`,
        };
      });
    },
    checkVerticalAlignment() {
      return window.innerHeight > window.innerWidth;
    },
  },
};
</script>

<style></style>
