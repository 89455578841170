<template>
  <div>
    <v-container class="mt-5">
      <subdivisions-table :subdivisions="mappedSubdivisions" />
    </v-container>
    <v-container class="mt-5">
      <teams-table :teams="teams" :adminCms="adminCms" :users="users" />
    </v-container>
    <v-container class="mt-5">
      <v-row>
        <v-col>
          <v-btn
            :loading="isLoading && clicked === 'data'"
            color="success"
            class="mx-2"
            @click="getData()"
          >
            Refresh data
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _cloneDeep from "lodash.clonedeep";
import SubdivisionsTable from "../components/subdivision/SubdivisionsTable.vue";
import TeamsTable from "../components/teams/TeamsTable.vue";

export default {
  components: {
    SubdivisionsTable,
    TeamsTable,
  },
  data() {
    return {
      clicked: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.clicked = "data";
      this.$store.dispatch("getSubdivisions");
      this.$store.dispatch("getTeams");
      this.$store.dispatch("getUsers");
      this.$store.dispatch("getAdminCms");
      this.$store.dispatch("getPermissions");
    },
    sendNotifications() {
      this.clicked = "notifications";
      this.$store.dispatch("sendNotifications");
    },
  },
  computed: {
    ...mapGetters(["subdivisions", "teams", "isLoading", "users", "adminCms"]),
    mappedSubdivisions() {
      return _cloneDeep(this.subdivisions);
    },
  },
};
</script>
