<template>
  <div>
    <v-card :height="$vuetify.breakpoint.smAndDown ? '100vh' : ''">
      <v-card-title>
        <span class="headline">Comment for job</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  outlined
                  v-model="note.title"
                  label="Comment Title"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="8">
                <v-textarea
                  outlined
                  required
                  label="Comment"
                  v-model="note.text"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-file-input
          :disabled="!$can('create', 'uploadPictures')"
          :loading="isLoading"
          small-chips
          dense
          multiple
          label="Upload photos"
          prepend-icon="mdi-camera"
          truncate-length="15"
          type="file"
          accept="image/*, application/pdf"
          @change="prepareUpload($event)"
          id="file-input"
        ></v-file-input>
        <v-btn color="error" text @click="close()">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!checkNoteCompletion()"
          :loading="isLoading"
          text
          @click="save()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import _cloneDeep from "lodash.clonedeep";

function initialState() {
  return {
    valid: false,
    note: {
      title: "",
      text: "",
    },
    photoFiles: [],
  };
}
export default {
  props: ["job", "closeDialog", "isLoading"],
  data() {
    return initialState();
  },
  mounted() {
    this.resetWindow();
  },
  methods: {
    resetWindow() {
      this.prepareUpload(null);
      Object.assign(this.$data, initialState());
    },
    checkNoteCompletion() {
      return this.note.title && this.note.text;
    },
    async save() {
      if (this.checkNoteCompletion()) {
        let comment = Object.assign({}, this.note, {
          subdivision_name: this.job.subdivision_name,
          lot_id: this.job.lot_id,
          job_id: this.job.job_id,
        });
        this.$store.dispatch("setLoading", true);
        let urls = [];
        if (this.photoFiles.length) {
          let data = new FormData();
          data.append("name", "images");
          this.photoFiles.forEach((f) => data.append("file_list", f));

          let config = {
            header: {
              "Content-Type": "image/png",
            },
          };

          urls = await this.$store.dispatch("uploadImage", {
            imageData: data,
            config: config,
          });
        }
        comment["photo_urls"] = urls;
        this.$store.dispatch("addComment", comment);
      } else {
        alert("Note must have title and text");
      }
    },
    close() {
      this.resetWindow();
      this.closeDialog();
    },
    prepareUpload($event) {
      if ($event) {
        this.photoFiles = $event;
      }
    },
    uploadImage() {},
  },

  computed: {
    ...mapGetters(["isLoading"]),
  },
  watch: {
    isLoading(l) {
      if (!l) {
        this.close();
      }
    },
  },
};
</script>

<style></style>
