<template>
  <div>
    <job-kan-ban :jobId="jobId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import JobKanBan from "../components/jobs/JobKanBan.vue";

export default {
  props: ["jobId"],
  components: { JobKanBan },
  data() {
    return {};
  },
  mounted() {
    if (!this.permissions) {
      this.getData();
    }
  },
  methods: {
    getData() {
      if (this.token) {
        this.$store.dispatch("getSubdivisions");
        this.$store.dispatch("getTeams");
        this.$store.dispatch("getUsers");
        this.$store.dispatch("getAdminCms");
        this.$store.dispatch("getPermissions");
      }
    },
  },
  computed: {
    ...mapGetters([
      "subdivisions",
      "teams",
      "isLoading",
      "users",
      "adminCms",
      "permissions",
      "token",
    ]),
  },
};
</script>
