<template>
  <v-card raised outlined>
    <v-row align="center" class="mt-5" justify="center">
      <v-container>
        <p class="text-center title">Add Job</p>
        <v-form class="ml-5 mr-5 mb-5 mt-5" ref="form" v-model="valid">
          <v-col cols="12">
            <v-select
              v-model="job._id"
              :items="mappedSubdivisions"
              label="Subdivision"
            ></v-select>
            <v-select
              v-model="job.lot_id"
              :items="mappedLots()"
              label="Lot"
            ></v-select>
            <v-text-field
              v-model="job.title"
              :disabled="!job._id && !job.lot_id"
              :rules="[(v) => !!v || 'Last name is required']"
              label="Title"
            ></v-text-field>

            <v-menu
              ref="menu"
              v-if="!nonDates().includes(job.status)"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="job.status"
                  label="Job date selection"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="job.status"
                no-title
                scrollable
                label="Select date status"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

            <v-select
              v-if="nonDates().includes(job.status)"
              v-model="job.status"
              :items="statusList"
              :loading="isLoading"
              label="Status"
            ></v-select>

            <v-text-field
              v-if="!jobProp"
              v-model="job.comment"
              :disabled="!job._id && !job.lot_id"
              label="Any comments?"
            ></v-text-field>
            <v-select
              v-model="job.team_id"
              :items="mappedTeams"
              :disabled="!job._id && !job.lot_id"
              label="Team id"
            ></v-select>
            <v-text-field
              v-model="job.lot_manager"
              :disabled="!job._id && !job.lot_id"
              label="Superintendent"
            ></v-text-field>
          </v-col>
        </v-form>
      </v-container>
    </v-row>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="error" text @click="cancel">Cancel</v-btn>
      <v-btn
        color="primary"
        :disabled="!job._id && !job.lot_id"
        text
        @click="save(job)"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import _cloneDeep from "lodash.clonedeep";
import { nonDateStatusList } from "../../globals/global";
import { statusList } from "../../globals/global";

function initialState() {
  return {
    job: {
      _id: "",
      lot_id: "",
      team_id: "",
      status: "",
      lot_manager: "",
      title: "",
      comment: "",
    },
    date: null,
    menu: null,
    statusList: statusList,
    valid: false,
  };
}

export default {
  props: ["subdivisions", "save", "cancel", "jobProp", "teams"],
  data() {
    return initialState();
  },
  mounted() {
    if (this.jobProp) {
      this.mapKeys();
    }
  },
  created() {
    this.resetWindow();
  },
  methods: {
    resetWindow() {
      Object.assign(this.$data, initialState());
    },
    nonDates() {
      return nonDateStatusList;
    },
    mapKeys() {
      Object.keys(this.job).forEach((k) => {
        this.job[k] = this.jobProp[k];
        if (
          k === "status" &&
          this.jobProp[k] &&
          !nonDateStatusList.includes(this.jobProp[k])
        ) {
          this.job[k] = this.$dayjs(this.jobProp[k])
            .toISOString()
            .substr(0, 10);
        }
      });
      this.job["job_id"] = this.jobProp.job_id;
      this.job["is_archived"] = false;
    },
    mappedLots() {
      if (this.job?._id) {
        return _cloneDeep(this.subdivisions)
          .find((s) => s._id === this.job._id)
          .lots.map((x) => {
            return {
              text: x.lot_number,
              value: x.lot_id,
            };
          });
      }
    },
  },
  computed: {
    mappedSubdivisions() {
      return _cloneDeep(this.subdivisions)
        .map((x) => {
          return {
            text: x.subdivision_name,
            value: x._id,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    mappedTeams() {
      return _cloneDeep(this.teams).map((x) => {
        return {
          text: x.team_name,
          value: x._id,
        };
      });
    },
  },
  watch: {
    jobProp(j) {
      if (j) this.mapKeys();
    },
  },
};
</script>

<style>
</style>
