export const SET_SELECTED_JOB_ID = "SET_SELECTED_JOB_ID";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_SUBDIVISIONS = "SET_SUBDIVISIONS";
export const SET_LOADING = "SET_LOADING";
export const SET_USERS = "SET_USERS";
export const SET_ADMIN_CMS = "SET_ADMIN_CMS";
export const SET_TEAMS = "SET_TEAMS";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_TOKEN = "SET_TOKEN";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const SHOW_OVERLAY = "SHOW_OVERLAY";
export const HIDE_OVERLAY = "HIDE_OVERLAY";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";