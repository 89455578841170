import * as types from "../mutation-types";
import { Ability } from "@casl/ability";
import axios from "axios";
// import { getInstance } from '../../auth/index'
import router from "../../router/index.js";

export const TYPE_KEY = Symbol("resourceType");

export function typeAs(type) {
  return (item) => {
    item[TYPE_KEY] = type;
    return item;
  };
}

let config = {
  headers: {
    "x-token": "",
  },
};

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "x-token": "",
  },
});

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (!getters.authenticated && !window.location.href.includes("login")) {
        router.push("/login");
      }
    }
    return Promise.reject(error);
  }
);

// STATE
const state = {
  rentToOwnRates: null,
  selectedJobId: null,
  subdivisions: [],
  permissions: null,
  adminCms: null,
  token: null,
  isLoading: false,
  snackBar: {
    show: false,
    text: "",
  },
  users: [],
  teams: [],
  authenticated: false,
};

// GETTERS
const getters = {
  authenticated: (s) => s.authenticated,
  rentToOwnRates: (s) => s.rentToOwnRates,
  selectedJobId: (s) => s.selectedJobId,
  permissions: (s) => s.permissions,
  subdivisions: (s) => s.subdivisions,
  token: (s) => s.token,
  users: (s) => s.users,
  adminCms: (s) => s.adminCms,
  teams: (s) => s.teams,
  snackBar: (s) => s.snackBar,
  isLoading: (s) => s.isLoading,
  ability() {
    return new Ability([], {
      subjectName(subject) {
        return !subject || typeof subject === "string"
          ? subject
          : subject[TYPE_KEY];
      },
    });
  },
};

// ACTIONS
const actions = {
  setSelectedJobId({ commit }, selectedJobId) {
    commit(types.SET_SELECTED_JOB_ID, selectedJobId);
  },
  setLoading({ commit }, isLoading) {
    commit(types.SET_LOADING, isLoading);
  },
  setToken({ commit }, token) {
    commit(types.SET_TOKEN, null);
    commit(types.SET_TOKEN, token);
  },
  sendNotifications({ commit, dispatch, getters }) {
    dispatch("setLoading", true);
    if (!getters.authenticated) return;
    config.headers["x-token"] = getters.token;
    HTTP.get("sendNotifications", config)
      .then(() => {
        commit(types.SHOW_SNACKBAR, "Teams have been notified!");
      })
      .catch(() => {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  getAdminCms({ commit, dispatch, getters }) {
    dispatch("setLoading", true);
    if (!getters.authenticated) return;
    config.headers["x-token"] = getters.token;
    HTTP.get("adminCms", config)
      .then((response) => {
        commit(types.SET_ADMIN_CMS, null);
        commit(types.SET_ADMIN_CMS, response.data);
      })
      .catch(() => {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  getSubdivisions({ commit, dispatch, getters }) {
    dispatch("setLoading", true);
    if (!getters.authenticated) return;
    config.headers["x-token"] = getters.token;
    HTTP.get("subdivisions", config)
      .then((response) => {
        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(() => {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  getTeams({ commit, dispatch, getters }) {
    dispatch("setLoading", true);
    if (!getters.authenticated) return;
    config.headers["x-token"] = getters.token;
    HTTP.get("teams", config)
      .then((response) => {
        commit(types.SET_TEAMS, []);
        commit(types.SET_TEAMS, response.data);
      })
      .catch(function () {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  createTeam({ commit, dispatch, getters }, team) {
    dispatch("setLoading", true);
    if (!getters.authenticated) return;
    config.headers["x-token"] = getters.token;
    HTTP.post("team", team, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Team Created!");
        commit(types.SET_TEAMS, []);
        commit(types.SET_TEAMS, response.data);
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error creating team ");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  updateTeam({ commit, dispatch, getters }, team) {
    dispatch("setLoading", true);
    if (!getters.authenticated) return;
    config.headers["x-token"] = getters.token;
    HTTP.put("team", team, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Team Updated!");

        commit(types.SET_TEAMS, []);
        commit(types.SET_TEAMS, response.data);
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error updating team ");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  createSubdivision({ commit, dispatch, getters }, subdivision) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.post("subdivisions", subdivision, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Subdivision Created!");

        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(function () {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  updateSubdivision({ commit, dispatch, getters }, sub) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.put("subdivision", sub, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Subdivision Updated!");

        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error updating subdivision");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  deleteSubdivision({ commit, dispatch, getters }, id) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.delete(`subdivision/${id}`, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Subdivision archived!");
        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error archiving subdivision!");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  createLot({ commit, dispatch, getters }, lot) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.post("lot", lot, config)
      .then((response) => {
        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
        commit(types.SHOW_SNACKBAR, "Lot created!");
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error creating lot");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  updateLot({ commit, dispatch, getters }, lot) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.put("lot", lot, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Lot updated!");
        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error updating lot");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  updateJobStatus({ commit, dispatch, getters }, jobStatus) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.post("jobStatus", jobStatus, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Job updated!");
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(function () {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  createJob({ commit, dispatch, getters }, jobStatus) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.post("job", jobStatus, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Job created!");
        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error creating job!");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  updateJob({ commit, dispatch, getters }, job) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.post("jobUpdate", job, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Job updated!");
        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error updating job");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  uploadImage({ dispatch, commit, getters }, info) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    return HTTP.post("photos", info.imageData, info.config)
      .then((response) => {
        return response.data.photo_paths;
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error uploading image(s)!");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  addComment({ commit, dispatch, getters }, comment) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.post("jobComments", comment, config)
      .then((response) => {
        commit(types.SHOW_SNACKBAR, "Comment posted!");
        commit(types.SET_SUBDIVISIONS, []);
        commit(types.SET_SUBDIVISIONS, response.data);
      })
      .catch(function () {
        commit(types.SHOW_SNACKBAR, "Error posting comment!");
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  getPermissions({ commit, dispatch, getters }) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.get("/permissions", config)
      .then((response) => {
        if (response.data?.error) {
          commit(types.SHOW_SNACKBAR, response.data.error);
          commit(types.HIDE_OVERLAY);
        } else {
          commit(types.SET_PERMISSIONS, response.data);
          const userPermissions = response.data.map((p) => {
            let action = p.split(":")[0];
            let subject = p.split(":")[1];

            return {
              action: action,
              subject: subject,
            };
          });
          getters.ability.update(userPermissions);
        }
      })
      .catch(function () {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  getUsers({ commit, dispatch, getters }) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.get("/users", config)
      .then((response) => {
        if (response.data?.error) {
          commit(types.SHOW_SNACKBAR, response.data.error);
          commit(types.HIDE_OVERLAY);
        } else {
          commit(types.SET_USERS, []);
          commit(types.SET_USERS, response.data);
        }
      })
      .catch(function () {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  updateUser({ commit, dispatch, getters }) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.get("/users", config)
      .then((response) => {
        if (response.data?.error) {
          commit(types.SHOW_SNACKBAR, response.data.error);
          commit(types.HIDE_OVERLAY);
        } else {
          commit(types.SET_USERS, []);
          commit(types.SET_USERS, response.data);
        }
      })
      .catch(function () {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  deleteUser({ commit, dispatch, getters }) {
    config.headers["x-token"] = getters.token;
    if (!getters.authenticated) return;
    HTTP.get("/users", config)
      .then((response) => {
        if (response.data?.error) {
          commit(types.SHOW_SNACKBAR, response.data.error);
          commit(types.HIDE_OVERLAY);
        } else {
          commit(types.SET_USERS, []);
          commit(types.SET_USERS, response.data);
        }
      })
      .catch(function () {})
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  showSnackBar({ commit }, text) {
    commit(types.SHOW_SNACKBAR, text);
  },
  setAuth({ commit }, isAuthenticated) {
    commit(types.SET_AUTHENTICATED, isAuthenticated);
  },
  hideSnackbar({ commit }) {
    commit(types.HIDE_SNACKBAR);
  },
};

// MUTATIONS
const mutations = {
  SET_RENT_TO_OWN_RATES(s, rentToOwnRates) {
    s.rentToOwnRates = rentToOwnRates;
  },
  SHOW_SNACKBAR(s, text) {
    s.snackBar.text = text;
    s.snackBar.show = true;
  },
  HIDE_SNACKBAR(s) {
    s.snackBar.text = "";
    s.snackBar.show = false;
  },
  SET_ADMIN_CMS(s, adminCms) {
    s.adminCms = adminCms;
  },
  SET_SELECTED_JOB_ID(s, selectedJobId) {
    s.selectedJobId = selectedJobId;
  },
  SET_PERMISSIONS(s, permissions) {
    s.permissions = permissions;
  },
  SET_TOKEN(s, token) {
    s.token = token;
  },
  SET_SUBDIVISIONS(s, subdivisions) {
    s.subdivisions = subdivisions;
  },
  SET_TEAMS(s, teams) {
    s.teams = teams;
  },
  SET_USERS(s, users) {
    s.users = users;
  },
  SET_LOADING(s, isLoading) {
    s.isLoading = isLoading;
  },
  SET_AUTHENTICATED(s, authenticated) {
    s.authenticated = authenticated;
  },
};

// EXPORT
export default {
  state,
  getters,
  actions,
  mutations,
};
