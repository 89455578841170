<template color="primary" dark v-slot:prepend>
  <div>
    <v-list-item two-line>
      <v-list-item-avatar>
        <img width="95" src="../../assets/logo.png" />
      </v-list-item-avatar>

      <v-list-item-content v-if="$auth.user">
        <v-list-item-title>{{ $auth.user.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider />
    <v-list v-if="$auth.user">
      <v-list-item
        v-for="item in filteredNav()"
        :key="item.title"
        link
        :to="item.path"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon class="mr-2">mdi-{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <div class="pa-1 ml-7">
      <!-- <v-switch
        v-model="toggleDarkMode"
        inset
        color="accent"
        label="Dark mode"
      ></v-switch> -->
      <div>
        <div class="pa-2">
          <v-btn
            :loading="logoutClicked ? isLoading : false"
            block
            @click="logout"
            >Logout</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoutClicked: false,
      selectedRole: null,
      selectedUser: null,
      toggleDarkMode: false,
      navItems: [
        { title: "Calendar", icon: "calendar", path: "/calendar" },
        {
          title: "Job Assignments",
          icon: "format-list-checks",
          path: "/board",
        },
        {
          title: "Subdivisions",
          icon: "home-group",
          path: "/subdivisions",
        },
        {
          title: "Lots",
          icon: "home",
          path: "/lots",
        },
        {
          title: "Jobs",
          icon: "hammer",
          path: "/jobs",
        },
      ],
    };
  },
  mounted() {
    // this.selectedRole = "5ee26f02d0b993d3bde89097"
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin + "/#/login",
      });
    },
    filteredNav() {
      return this.navItems.filter((n) => {
        if (["Subdivisions", "Lots", "Jobs"].includes(n.title)) {
          return this.$can("read", "subdivisions");
        }
        if (n.title === "Job Assignments") {
          return this.$can("read", "calendarViewWeek");
        }
        if (n.title === "Calendar") {
          return this.$can("read", "calendarViewMonth");
        }
      });
    },
  },
};
</script>

<style>
.icon {
  display: block;
  font-size: 0;
}
</style>
