<template>
  <v-row class="fullHeight" :justify="'center'" no-gutters>
    <v-col cols="8" v-if="$vuetify.breakpoint.mdAndUp" class="imgClass"></v-col>
    <v-col>
      <v-container fluid fill-height class="background">
        <v-row align-center justify-center>
          <v-flex class="margins">
            <v-row :justify="'center'">
              <img
                src="../assets/logo.png"
                width="400"
                height="200"
                alt="Solid tech logo"
              />
            </v-row>
            <v-row :justify="'center'">
              <p class="headline">Solid Tech Login</p>
            </v-row>
            <v-row class="mt-5" :justify="'center'">
              <v-btn color="primary" @click="SignIn()">Sign In</v-btn>
            </v-row>
          </v-flex>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    async SignIn() {
      this.$auth.loginWithRedirect({
        appState: { targetUrl: "board" },
      });
    },
  },
  watch: {},
};
</script>
<style>
.imgClass {
  background: url(https://images.unsplash.com/photo-1604916932798-c4a82d922004?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80);
}
.fullHeight {
  height: 100%;
}
.margins {
  width: 50%;
  margin-left: 20%;
  margin-right: 20%;
}
</style>
