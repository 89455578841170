<template>
  <div>
    <v-row v-if="job.comments">
      <v-col
        v-if="
          $can('read', 'managerComments') &&
          getCommentsForRoles('Manager').length
        "
        :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'"
      >
        <display-comments
          title="Manager Comments"
          :displayPhotos="(commentDate) => displayPhotos(commentDate)"
          :comments="getCommentsForRoles('Manager')"
        />
      </v-col>
      <v-col
        v-if="
          $can('read', 'installerComments') &&
          getCommentsForRoles('Installer').length
        "
        :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'"
      >
        <display-comments
          title="Installer Comments"
          :displayPhotos="(commentDate) => displayPhotos(commentDate)"
          :comments="getCommentsForRoles('Installer')"
        />
      </v-col>
      <v-col
        v-if="getCommentsForRoles('Admin').length"
        :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'"
      >
        <display-comments
          title="Admin Comments"
          :displayPhotos="(commentDate) => displayPhotos(commentDate)"
          :comments="getCommentsForRoles('Admin')"
        />
      </v-col>
      <v-col
        v-if="getCommentsForRoles('TeamMember').length"
        :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'"
      >
        <display-comments
          title="Team Member Comments"
          :displayPhotos="(commentDate) => displayPhotos(commentDate)"
          :comments="getCommentsForRoles('TeamMember')"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-10" v-if="!job.comments">
      <p class="text-h5 text-center lighten-2">No comments</p>
    </v-row>
    <v-row justify="center" no-gutters class="text-center mt-4">
      <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
        <v-select
          v-model="status"
          :items="statusList"
          :loading="isLoading"
          label="Status"
        ></v-select>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
        <v-btn
          :disabled="!$can('create', 'comment')"
          color="primary"
          text
          @click="addNoteDialog = true"
          >Add Comment</v-btn
        >
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
        <v-btn color="danger" text @click="closeDialog()">Close</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="addNoteDialog"
      max-width="700px"
    >
      <add-note
        v-if="addNoteDialog"
        :job="job"
        :isLoading="isLoading"
        :closeDialog="() => cancel()"
      />
    </v-dialog>
    <v-dialog
      v-if="selectedComment"
      v-model="displayPhotoDialog"
      width="500"
      height="500"
    >
      <v-card class="pb-4">
        <v-carousel>
          <template v-for="(item, i) in selectedComment.photo_urls">
            <v-carousel-item :key="i">
              <pdf
                v-if="isPDF(item)"
                :src="item"
                :page="1"
                :show-toolbar="false"
                :show-nav="false"
                style="height: 500px"
              ></pdf>
              <v-img v-else :src="item" contain height="500"></v-img>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddNote from "./AddNote";
import DisplayComments from "./DisplayComments.vue";
import _cloneDeep from "lodash.clonedeep";
import { statusList } from "../../globals/global";

export default {
  components: {
    AddNote,
    DisplayComments,
  },
  props: ["job", "closeDialog", "isLoading", "mappedSubdivisons"],
  data: () => ({
    selected: 0,
    selectedComment: null,
    file: null,
    addNoteDialog: false,
    displayPhotoDialog: false,
    selectedImg: null,
    statusSetter: null,
    statusList: statusList,
  }),
  mounted() {
    this.copyJob();
  },
  methods: {
    cancel() {
      this.addNoteDialog = false;
    },
    displayPhotos(commentDate) {
      this.displayPhotoDialog = true;
      this.selectedComment = this.job.comments.find(
        (c) => c.date_created === commentDate
      );
    },
    getCommentsForRoles(roleName) {
      return this.job.comments.filter((c) => c.role_name === roleName);
    },
    copyJob() {
      this.statusSetter = _cloneDeep(this.job.status);
    },
    updateStatus(status) {
      const foundSubdivision = this.mappedSubdivisons.find(
        (s) => s.job_id === this.job.job_id
      );
      const job = {
        job_id: this.job.job_id,
        lot_id: foundSubdivision.lot_id,
        subdivision_name: foundSubdivision.subdivision_name,
        status: status,
      };
      this.$store.dispatch("updateJobStatus", job);
    },
    isPDF(url) {
      return url.toLowerCase().endsWith("pdf");
    },
  },
  computed: {
    status: {
      get: function () {
        return this.statusSetter;
      },
      set: function (newValue) {
        this.updateStatus(newValue);
      },
    },
  },
};
</script>

<style></style>
