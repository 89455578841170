<template>
  <div v-if="mappedSubdivisions">
    <v-data-table
      :headers="headers"
      :items="mappedSubdivisions"
      dense
      class="elevation-1"
      :search="search"
      :items-per-page="10"
      sort-by="lot_id"
      sort-desc
      fixed-header
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color>
          <v-toolbar-title>Lots</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <div class="flex-grow-1"></div>
          <v-text-field
            v-model="search"
            outlined
            dense
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <div class="flex-grow-1"></div>
          <v-btn
            :disabled="!$can('create', 'lot')"
            @click="editOrCreateLot()"
            color="primary"
            dark
            class="mb-2"
            >Create Lot</v-btn
          >
          <v-dialog v-model="dialog" max-width="600px">
            <create-lot
              :key="editedIndex"
              :save="(lot) => save(lot)"
              :cancel="() => cancel()"
              :subdivisions="subdivisions"
              :lotProp="lot"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          :disabled="!$can('update', 'lot')"
          small
          class="mr-2"
          @click="editOrCreateLot(item)"
          >mdi-pencil</v-icon
        >
        <v-icon
          :disabled="!$can('delete', 'lot')"
          small
          @click="deleteLot(item)"
          >mdi-delete</v-icon
        >
      </template>
      <template v-slot:no-data>
        <p class="headline">No data</p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CreateLot from "./CreateLot.vue";
import _cloneDeep from "lodash.clonedeep";

export default {
  components: {
    CreateLot,
  },
  props: ["subdivisions"],
  data: () => ({
    dialog: false,
    search: null,
    lot: null,
    editedIndex: 0,
    headers: [
      {
        text: "Lot #",
        align: "left",
        value: "lot_number",
      },
      {
        text: "Created",
        value: "display_date_created",
      },
      { text: "Subdivision name", value: "subdivision_name" },
      { text: "Address", value: "lot_address" },
      { text: "Actions", value: "action", sortable: false },
    ],
  }),
  methods: {
    editOrCreateLot(item) {
      if (item) {
        this.editedIndex = 1;
        this.lot = this.mappedSubdivisions.find(
          (j) => j.lot_id === item.lot_id
        );
      } else {
        this.lot = "test";
        this.lot = null;
        this.editedIndex = -1;
      }
      this.dialog = true;
    },
    deleteLot(lot) {
      lot.is_archived = true;
      alert("Are you sure you want to delete this job?") ||
        this.$store.dispatch("updateLot", lot);
    },
    cancel() {
      this.dialog = false;

      setTimeout(() => {
        this.lot = null;
        this.editedIndex = -1;
      }, 300);
    },
    save(lot) {
      if (this.editedIndex > -1) {
        this.$store.dispatch("updateLot", lot);
      } else {
        this.$store.dispatch("createLot", lot);
      }

      this.cancel();
    },
  },
  computed: {
    mappedSubdivisions() {
      // this.test = this.subdivisions.filter(x => x);
      const clonedSubDivs = _cloneDeep(this.subdivisions);
      return clonedSubDivs.flatMap((s) => {
        return s.lots.flatMap((l) => {
          delete s.lots;
          l.display_date_created = this.$dayjs(l.date_created).format(
            "MMM D YYYY, h:mm a"
          );
          return Object.assign({}, l, s);
        });
      });
    },
    formTitle() {
      return this.editedIndex === -1
        ? "New Subdivision"
        : "Edit Subdivision/Lot";
    },
  },
  watch: {
    dialog(val) {
      val || this.cancel();
    },
    products() {
      this.$store.dispatch("hideOverlay");
    },
  },
};
</script>

<style>
</style>
