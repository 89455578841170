<template>
  <div v-if="mappedSubdivisions">
    <v-data-table
      :headers="headers"
      :items="mappedSubdivisions"
      dense
      class="elevation-1"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1],
      }"
      sort-by="lot_id"
      sort-desc
      fixed-header
    >
      <template v-slot:top>
        <v-toolbar flat color>
          <v-toolbar-title>Jobs</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <div class="flex-grow-1"></div>
          <v-text-field
            v-model="search"
            outlined
            dense
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <div class="flex-grow-1"></div>
          <v-btn
            :disabled="!$can('create', 'job')"
            @click="editOrCreateJob()"
            color="primary"
            dark
            class="mb-2"
            >Create Job</v-btn
          >
          <v-dialog v-model="dialog" max-width="600px">
            <create-job
              v-if="dialog"
              :key="editedIndex"
              :save="(job) => save(job)"
              :cancel="() => cancel()"
              :subdivisions="subdivisions"
              :jobProp="job"
              :teams="teams"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          :disabled="!$can('update', 'job')"
          small
          class="mr-2"
          @click="editOrCreateJob(item)"
          >mdi-pencil</v-icon
        >
        <v-icon
          :disabled="!$can('delete', 'job')"
          small
          @click="deleteJob(item)"
          >mdi-delete</v-icon
        >
      </template>
      <template v-slot:no-data>
        <p class="headline">No data</p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CreateJob from "./CreateJob.vue";
import _cloneDeep from "lodash.clonedeep";
import { nonDateStatusList } from "../../globals/global";

export default {
  components: {
    CreateJob,
  },
  props: ["subdivisions", "teams"],
  data: () => ({
    dialog: false,
    search: null,
    job: null,
    editedIndex: 0,
    headers: [
      {
        text: "Job Title",
        align: "left",
        value: "title",
      },
      {
        text: "Created",
        value: "display_date_created",
      },
      {
        text: "Lot #",
        value: "lot_number",
      },
      {
        text: "Subdivision",
        value: "subdivision_name",
      },

      { text: "Team id", value: "team_name" },
      { text: "Status", value: "display_status" },
      { text: "Actions", value: "action", sortable: false },
    ],
  }),
  methods: {
    editOrCreateJob(item) {
      if (item) {
        this.editedIndex = 1;
        this.job = this.mappedSubdivisions.find(
          (j) => j.job_id === item.job_id
        );
      } else {
        this.job = null;
        this.editedIndex = -1;
      }
      this.dialog = true;
    },
    deleteJob(job) {
      job.is_archived = true;
      alert("Are you sure you want to delete this job?") ||
        this.$store.dispatch("updateJob", job);
    },
    cancel() {
      this.dialog = false;

      setTimeout(() => {
        this.job = null;
        this.editedIndex = -1;
      }, 300);
    },
    save(job) {
      if (this.editedIndex > -1) {
        this.$store.dispatch("updateJob", job);
      } else {
        this.$store.dispatch("createJob", job);
      }

      this.cancel();
    },
  },
  computed: {
    mappedSubdivisions() {
      const team_id =
        this.$auth.user["https://my-app.example.com/app_metadata"].team_id;
      const role = this.$auth.user["https://my-app.example.com/roles"][0];
      const clonedSubDivs = _cloneDeep(this.subdivisions);
      return clonedSubDivs.flatMap((s) => {
        return s.lots.flatMap((l) => {
          return l.jobs
            .map((j) => {
              delete l.jobs;
              delete s.lots;
              if (!nonDateStatusList.includes(j.status)) {
                j.display_status = this.$dayjs(j.status).format("MMM D YY");
              } else {
                switch (j.status) {
                  case "status-7":
                    j.display_status = "In Progress";
                    break;
                  case "status-8":
                    j.display_status = "Done";
                    break;
                  case "verified_completed":
                    j.display_status = "Verified Completed";
                    break;
                  default:
                    break;
                }
              }

              j.display_date_created = this.$dayjs(j.date_created).format(
                "MMM D YYYY, h:mm a"
              );
              j.team_name = this.teams.find(
                (t) => t._id === j.team_id
              )?.team_name;

              return Object.assign({}, j, l, s);
            })
            .filter((j) => role === "Admin" || j.team_id === team_id);
        });
      });
    },
    formTitle() {
      return this.editedIndex === -1
        ? "New Subdivision"
        : "Edit Subdivision/Lot";
    },
  },
  watch: {
    dialog(val) {
      val || this.cancel();
    },
    products() {
      this.$store.dispatch("hideOverlay");
    },
  },
};
</script>

<style>
</style>
