import Vue from "vue";
import VueRouter from "vue-router";

import Calendar from "../views/CalendarView.vue";
import BoardView from "../views/BoardView.vue";
import SubdivisionView from "../views/SubdivisionsView.vue";
import LotView from "../views/LotView.vue";
import JobView from "../views/JobView.vue";

import LoginView from "../views/LoginView.vue";

import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    beforeEnter: authGuard,
  },
  {
    path: "/subdivisions",
    name: "Subdivisions",
    component: SubdivisionView,
    beforeEnter: authGuard,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    beforeEnter: authGuard,
  },
  {
    path: "/board/",
    name: "Job Assignments",
    component: BoardView,
    props: (route) => ({ jobId: route.query.jobId }),
    beforeEnter: authGuard,
  },
  {
    path: "/lots/",
    name: "Lots",
    component: LotView,
    beforeEnter: authGuard,
  },
  {
    path: "/jobs/",
    name: "Jobs",
    component: JobView,
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  base: "/board/",
  routes,
});

export default router;
