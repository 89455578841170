<template>
  <v-card raised outlined>
    <v-row align="center" class="mt-5" justify="center">
      <v-container>
        <p class="text-center title">{{ teamProp ? "Edit" : "Add" }} team</p>
        <v-form class="ml-5 mr-5 mb-5 mt-5" ref="form" v-model="valid">
          <v-col v-if="team" cols="12">
            <v-text-field
              v-model="team.team_name"
              label="Team Name"
            ></v-text-field>
            <v-select
              v-model="team.members"
              :items="mappedUsers"
              label="Select Team Members"
              multiple
            >
            </v-select>
            <v-select
              v-model="team.type"
              :items="adminCms.team_types"
              label="Select team type"
            >
            </v-select>
          </v-col>
        </v-form>
      </v-container>
    </v-row>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="error" text @click="cancel()">Cancel</v-btn>
      <v-btn color="primary" text @click="save(team)">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import _cloneDeep from "lodash.clonedeep";

function initialState() {
  return {
    team: {
      team_name: "",
      members: [],
      type: "",
    },
    valid: false,
  };
}

export default {
  props: ["save", "cancel", "teamProp", "adminCms", "users"],
  data() {
    return initialState();
  },
  mounted() {
    if (this.teamProp) {
      Object.keys(this.team).forEach((k) => {
        this.team[k] = this.teamProp[k];
      });
      this.team._id = this.teamProp._id;
    }
  },
  created() {
    this.resetWindow();
  },
  methods: {
    resetWindow() {
      Object.assign(this.$data, initialState());
    },
  },
  computed: {
    mappedTeamTypes() {
      return _cloneDeep(this.adminCms.team_types)
        .map((x) => {
          return {
            text: x.team_name,
            value: x._id,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    mappedUsers() {
      return _cloneDeep(this.users).map((x) => {
        return {
          text: x.name || x.nick_name,
          value: x.user_id,
        };
      });
    },
  },
  watch: {
    teamProp(l) {
      this.team = l;
    },
  },
};
</script>

<style>
</style>
