<template>
  <div class="demo-app">
    <div class="demo-app-main">
      <FullCalendar class="demo-app-calendar" :options="config">
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>
    <v-dialog
      v-if="selectedJob"
      v-model="openDialog"
      width="1400"
      height="1400"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-app-bar dark color="blue">
        <v-toolbar-title>{{ `${selectedJob.title}` }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="$vuetify.breakpoint.smAndDown" icon>
          <v-icon @click="closeDialog()" color="red">mdi-close</v-icon>
        </v-btn>
        <v-progress-circular
          v-if="isLoading"
          :width="2"
          indeterminate
        ></v-progress-circular>
      </v-app-bar>
      <v-card class="pb-4">
        <v-card-title class="text-h6 grey lighten-2">{{
          `${selectedJob.lot_address} Sub: ${selectedJob.subdivision_name}`
        }}</v-card-title>
        <v-divider></v-divider>
        <job-details
          v-if="selectedJob"
          :mappedSubdivisons="mappedSubdivisions"
          class="mx-5 mt-5 mb-5"
          :job="selectedJob"
          :isLoading="isLoading"
          :closeDialog="closeDialog"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import JobDetails from "../jobs/JobDetails.vue";
import { nonDateStatusList } from "../../globals/global";

import { mapGetters } from "vuex";
import _cloneDeep from "lodash.clonedeep";

export default {
  components: {
    FullCalendar,
    JobDetails,
  },
  mounted() {
    this.$store.dispatch("getSubdivisions");
  },
  data: function () {
    return {
      selectedJob: null,
      openDialog: false,
    };
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      let title = prompt("Please enter a new title for your event");
      let calendarApi = selectInfo.view.calendar;
      calendarApi.unselect(); // clear date selection
      if (title) {
        calendarApi.addEvent({
          id: 4,
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    },
    onEventDrop({ event }) {
      const foundSubdivision = this.mappedSubdivisions.find(
        (j) => j.job_id === event.id
      );

      const job = {
        job_id: event.id,
        lot_id: foundSubdivision.lot_id,
        subdivision_name: foundSubdivision.subdivision_name,
        status: this.$dayjs(event.start).toISOString(),
      };
      this.$store.dispatch("updateJobStatus", job);
    },
    onEventClick({ event }) {
      this.selectedJob = this.mappedSubdivisions.find(
        (j) => j.job_id === event.id
      );
      this.openDialog = true;
    },
    closeDialog() {
      this.openDialog = false;
    },
  },
  computed: {
    ...mapGetters(["token", "subdivisions", "isLoading"]),
    mappedEvents() {
      const clonedSubDivs = _cloneDeep(this.subdivisions);
      return clonedSubDivs
        .flatMap((s) => {
          return s.lots.flatMap((l) => {
            return l.jobs.map((j) => {
              if (!nonDateStatusList.includes(j.status)) {
                j.start = this.$dayjs(j.status)
                  .toISOString()
                  .replace(/T.*$/, "");
                j.backgroundColor = "#45ADA8";
              } else {
                const current_status =
                  j.status_history[j.status_history.length - 1];
                if (current_status) {
                  if (current_status.status === "verified_completed") {
                    return null;
                  }
                  j.start = this.$dayjs(current_status.date_created)
                    .toISOString()
                    .replace(/T.*$/, "");
                  if (j.status === "status-7") {
                    j.backgroundColor = "#FF847C";
                  }
                  if (j.status === "status-8") {
                    j.backgroundColor = "#99B898";
                  }
                }
              }

              j.display_date_created = this.$dayjs(j.date_created).format(
                "MMM D YYYY, h:mm a"
              );

              return {
                start: j.start,
                title: j.title,
                id: j.job_id,
                backgroundColor: j.backgroundColor,
              };
            });
          });
        })
        .filter((j) => j);
    },
    mappedSubdivisions() {
      // this.test = this.subdivisions.filter(x => x);
      const clonedSubDivs = _cloneDeep(this.subdivisions);
      return clonedSubDivs.flatMap((s) => {
        return s.lots.flatMap((l) => {
          return l.jobs.map((j) => {
            delete l.jobs;
            delete s.lots;
            return Object.assign({}, j, l, s);
          });
        });
      });
    },
    config() {
      return {
        ...this.configOptions,
        ...this.eventHandlers,
      };
    },
    configOptions() {
      return {
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.mappedEvents,
        weekends: true,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
      };
    },
    eventHandlers() {
      return {
        dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventDrop,
        select: this.onDateSelect,
      };
    },
  },
};
</script>

<style scoped lang="css">
h2 {
  margin: 0;
  font-size: 16px;
}
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}
li {
  margin: 1.5em 0;
  padding: 0;
}
b {
  /* used for event dates/times */
  margin-right: 3px;
}
.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
</style>
