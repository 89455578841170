<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="mappedSubdivisions"
      sort-by="title"
      class="elevation-1"
      :search="search"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color>
          <v-toolbar-title>Subdivisions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <div class="flex-grow-1"></div>
          <v-text-field
            v-model="search"
            outlined
            dense
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <div class="flex-grow-1"></div>
          <v-btn
            :disabled="!$can('create', 'subdivision')"
            @click="editOrCreateSubdivision()"
            color="primary"
            dark
            class="mb-2"
            >Create subdivision</v-btn
          >
          <v-dialog v-model="dialog" max-width="600px">
            <create-sub-division
              v-if="dialog"
              :save="(s) => save(s)"
              :cancel="() => cancel()"
              :subdivisions="subdivisions"
              :subProp="subdivision"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          :disabled="!$can('update', 'subdivision')"
          small
          class="mr-2"
          @click="editOrCreateSubdivision(item)"
          >mdi-pencil</v-icon
        >
        <v-icon
          :disabled="!$can('delete', 'subdivision')"
          small
          @click="deleteSubdivision(item)"
          >mdi-delete</v-icon
        >
      </template>
      <template v-slot:no-data>
        <p class="headline">No data</p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CreateSubDivision from "./CreateSubDivision.vue";
import _cloneDeep from "lodash.clonedeep";

export default {
  components: {
    CreateSubDivision,
  },
  props: ["subdivisions"],
  data: () => ({
    dialog: false,
    search: null,
    subdivision: null,
    editedIndex: 0,
    headers: [
      { text: "Builder name", align: "left", value: "builder_name" },
      { text: "Subdivision name", value: "subdivision_name" },
      { text: "Created", value: "display_date_created" },
      { text: "Modified", value: "display_date_modified" },
      { text: "Company email", value: "company_email" },
      { text: "Address", value: "address" },
      { text: "Point of contact", value: "point_of_contact" },
      { text: "Address", value: "address" },
      { text: "Actions", value: "action", sortable: false },
    ],
  }),
  methods: {
    editOrCreateSubdivision(item) {
      if (item) {
        this.editedIndex = 1;
        this.subdivision = this.mappedSubdivisions.find(
          (j) => j._id === item._id
        );
      } else {
        this.subdivision = "test";
        this.subdivision = null;
        this.editedIndex = -1;
      }
      this.dialog = true;
    },
    deleteSubdivision(subdivision) {
      alert("Are you sure you want to delete this job?") ||
        this.$store.dispatch("deleteSubdivision", subdivision._id);
    },
    cancel() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save(subdiv) {
      if (this.editedIndex > -1) {
        this.$store.dispatch("updateSubdivision", subdiv);
      } else {
        this.$store.dispatch("createSubdivision", subdiv);
      }

      this.cancel();
    },
  },
  computed: {
    mappedSubdivisions() {
      // this.test = this.subdivisions.filter(x => x);
      const clonedSubDivs = _cloneDeep(this.subdivisions);
      return clonedSubDivs.map((s) => {
        s.display_date_created = this.$dayjs(s.date_created).format(
          "MMM D YYYY, h:mm a"
        );
        s.display_date_modified = this.$dayjs(s.date_modified).format(
          "MMM D YYYY, h:mm a"
        );
        return s;
      });
    },
    formTitle() {
      return this.editedIndex === -1
        ? "New Subdivision"
        : "Edit Subdivision/Lot";
    },
  },
  watch: {
    dialog(val) {
      val || this.cancel();
    },
    products() {
      this.$store.dispatch("hideOverlay");
    },
  },
};
</script>

<style>
</style>
