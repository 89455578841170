<template>
  <div v-if="mappedTeams">
    <v-data-table
      :headers="headers"
      :items="mappedTeams"
      dense
      class="elevation-1"
      :search="search"
      :items-per-page="5"
      sort-desc
      fixed-header
    >
      <template v-slot:top>
        <v-toolbar flat color>
          <v-toolbar-title>Teams</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <div class="flex-grow-1"></div>
          <v-text-field
            v-model="search"
            outlined
            dense
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <div class="flex-grow-1"></div>
          <v-btn
            :disabled="!$can('create', 'team')"
            @click="editOrCreateTeam()"
            color="primary"
            dark
            class="mb-2"
            >Create Team</v-btn
          >
          <v-dialog v-model="dialog" max-width="600px">
            <create-team
              :key="editedIndex"
              :save="(team) => save(team)"
              :cancel="() => cancel()"
              :teamProp="team"
              :adminCms="adminCms"
              :users="users"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          :disabled="!$can('update', 'team')"
          small
          class="mr-2"
          @click="editOrCreateTeam(item)"
          >mdi-pencil</v-icon
        >
      </template>
      <template v-slot:no-data>
        <p class="headline">No data</p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CreateTeam from "./CreateTeam.vue";
import _cloneDeep from "lodash.clonedeep";

export default {
  components: {
    CreateTeam,
  },
  props: ["teams", "adminCms", "users"],
  data: () => ({
    dialog: false,
    search: null,
    team: null,
    editedIndex: 0,
    headers: [
      {
        text: "Team Name",
        align: "left",
        value: "team_name",
      },
      {
        text: "Created",
        value: "display_date_created",
      },
      {
        text: "Team Type",
        value: "type",
      },
      { text: "Members", value: "displayed_members" },
      { text: "Actions", value: "action", sortable: false },
    ],
  }),
  methods: {
    editOrCreateTeam(item) {
      if (item) {
        this.editedIndex = 1;
        this.team = this.mappedTeams.find((j) => j._id === item._id);
      } else {
        this.team = "test";
        this.team = null;
        this.editedIndex = -1;
      }
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;

      setTimeout(() => {
        this.team = null;
        this.editedIndex = -1;
      }, 300);
    },
    save(team) {
      if (this.editedIndex > -1) {
        this.$store.dispatch("updateTeam", team);
      } else {
        this.$store.dispatch("createTeam", team);
      }

      this.cancel();
    },
  },
  computed: {
    mappedTeams() {
      return _cloneDeep(this.teams).map((t) => {
        return Object.assign({}, t, {
          display_date_created: this.$dayjs(t.date_created).format(
            "MMM D YYYY, h:mm a"
          ),
          displayed_members: t.members
            .map((m) => {
              const foundUser = this.users.find((u) => u.user_id === m)?.name;
              return foundUser ? ` ${foundUser} ` : null;
            })
            .filter((u) => u)
            .slice(0, 3),
        });
      });
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Team" : "Edit Team";
    },
  },
  watch: {
    dialog(val) {
      val || this.cancel();
    },
    products() {
      this.$store.dispatch("hideOverlay");
    },
  },
};
</script>

<style>
</style>
