import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import dayjs from 'dayjs'
import './plugins/vue-kanban'
// import DaySpanVuetify from 'dayspan-vuetify-2'

// Import the Auth0 configuration
import { domain, clientId, audience  } from '../auth_config.json';

// Import the plugin here
import { Auth0Plugin } from './auth';
import { abilitiesPlugin } from "@casl/vue";

Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs;

Vue.use(abilitiesPlugin, store.getters.ability);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
