<template>
  <v-card raised outlined>
    <v-row align="center" class="mt-5" justify="center">
      <v-container>
        <p class="text-center title">Add Subdivision</p>
        <v-form ref="form" v-model="valid">
          <v-col cols="12">
            <v-text-field
              v-model="subdivision.builder_name"
              :rules="[(v) => !!v || 'Subdivision name is required']"
              label="Builder name"
            ></v-text-field>
            <v-text-field
              v-model="subdivision.subdivision_name"
              :rules="[(v) => !!v || 'Subdivision name is required']"
              label="Subdivision name"
            ></v-text-field>
            <v-text-field
              v-model="subdivision.company_email"
              label="Company email"
            ></v-text-field>
            <v-text-field
              v-model="subdivision.address"
              :rules="[(v) => !!v || 'Address is required']"
              label="Address"
            ></v-text-field>
            <v-text-field
              v-model="subdivision.point_of_contact"
              label="Point of contact"
            ></v-text-field>
          </v-col>
        </v-form>
      </v-container>
    </v-row>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="error" text @click="cancel()">Cancel</v-btn>
      <v-btn color="primary" text @click="save(subdivision)">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import _cloneDeep from "lodash.clonedeep";

function initialState() {
  return {
    subdivision: {
      builder_name: "",
      subdivision_name: "",
      company_email: "",
      address: "",
      point_of_contact: "",
    },
    valid: false,
  };
}

export default {
  props: ["subdivisions", "save", "cancel", "subProp"],
  data() {
    return initialState();
  },
  mounted() {
    if (this.subProp) {
      Object.keys(this.subdivision).forEach((k) => {
        this.subdivision[k] = this.subProp[k];
      });
      this.subdivision._id = this.subProp._id;
    }
  },
  created() {
    this.resetWindow();
  },
  methods: {
    resetWindow() {
      Object.assign(this.$data, initialState());
    },
  },
  computed: {
    mappedSubdivisions() {
      return _cloneDeep(this.subdivisions)
        .map((x) => {
          return {
            text: x.subdivision_name,
            value: x._id,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  watch: {
    subProp(s) {
      this.subdivision = s;
    },
  },
};
</script>

<style>
</style>
